import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import WebApi from 'services/index';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { toast } from 'react-toastify';
import theme from 'shared/theme';

export const UI = ({
  handleClose,
  onChangeStep,
}: {
  handleClose?: Function;
  onChangeStep?: Function;
}) => {
  const router = useRouter();
  const [step, setStep] = useState(1);
  const [smsSucces, setSmsSucces] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPass, setShowPas] = useState(false);

  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const handlePhoneNumberSubmit = async (event: any) => {
    event.preventDefault();
    const { err, data } = await WebApi().SMS.sendSms({
      phone: phoneNumber,
      smsTemplate: 'resetPassword',
    });
    if (err) {
      setSmsSucces(false);
      setError(
        'Произошла ошибка при отправке СМС. Пожалуйста, попробуйте ещё раз'
      );
      return;
    } else {
      setError('');
      setSmsSucces(true);
      toast.info('Сообщение отправлено');
      if (remainingTime > 0) {
        return;
      }
      setIsTimerRunning(true);
      setRemainingTime(60);
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isTimerRunning]);

  const handleResetPassword = async (event: any) => {
    event.preventDefault();
    if (!smsCode) {
      setError('Введенные кода СМС');
      return;
    }
    if (!newPassword) {
      setError('Введите новый пароль');
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('Введенные пароли не совпадают');
      return;
    }
    const { err, _data } = await WebApi().SMS.resetPass({
      phoneNumber: phoneNumber,
      code: smsCode,
      password1: newPassword,
      password2: confirmPassword,
    });
    if (err) {
      setError(err.message);
      return;
    } else {
      setError('');
    }
    if (_data) {
      if (onChangeStep) {
        onChangeStep('1');
      } else {
        router.push('/auth/signin');
      }
    }
  };

  const togglePassword = () => {
    setShowPas((prevShowPass) => !prevShowPass);
  };

  return (
    <Card
      sx={{
        maxWidth: 550,
        padding: '96px',
        [theme.breakpoints.down('sm')]: { maxWidth: 300, padding: 3 },
      }}
    >
      <Typography variant="h5" mb={2}>
        Восстановление пароля
      </Typography>
      {step === 1 && (
        <form onSubmit={() => setStep(2)}>
          <InputMask
            mask="+7(\999)999-99-99"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          >
            <TextField
              fullWidth
              size="small"
              label="Номер телефона"
              placeholder="Введите номер телефона"
            />
          </InputMask>
          {error && (
            <Alert severity="error" sx={{ mb: 3, mt: 2 }}>
              {error}
            </Alert>
          )}
          {!phoneNumber.includes('_') ? (
            <>
              <Box mt={2} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <TextField
                  fullWidth
                  size="small"
                  label="Код из СМС"
                  placeholder="Введите код"
                  sx={{ width: '60%' }}
                  value={smsCode}
                  onChange={(e) => setSmsCode(e.target.value)}
                />
                <Button
                  onClick={handlePhoneNumberSubmit}
                  variant="contained"
                  disabled={remainingTime > 0}
                  sx={{ width: '35%', lineHeight: 1.1 }}
                >
                  {remainingTime > 0 ? `${remainingTime} сек` : 'Получить СМС'}
                </Button>
              </Box>
            </>
          ) : null}
          <Button
            type="submit"
            disabled={!smsCode}
            variant="contained"
            sx={{ mt: 2 }}
            fullWidth
          >
            Продолжить
          </Button>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleResetPassword}>
          <TextField
            type={showPass ? 'text' : 'password'}
            fullWidth
            label="Пароль"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Введите пароль"
            sx={{ mt: 2, mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePassword}>
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type={showPass ? 'text' : 'password'}
            fullWidth
            label="Подтвердите пароль"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Введите пароль"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePassword}>
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Alert severity="error" sx={{ mb: 3, mt: 2 }}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            disabled={!newPassword || !confirmPassword}
            sx={{ mt: 3 }}
            fullWidth
          >
            Подтвердить
          </Button>
        </form>
      )}
      <Box
        mb={2}
        sx={{
          mt: 1,
          color: 'rgba(0, 102, 255, 0.6)',
        }}
      >
        {onChangeStep ? (
          <Button
            sx={{
              background: '#fff',
              p: 0,
              fontSize: 16,
              color: 'rgb(0, 102, 255)',
            }}
            onClick={() => onChangeStep('1')}
          >
            Вернуться на главную
          </Button>
        ) : (
          <Link href="/">Вернуться на главную</Link>
        )}
      </Box>
    </Card>
  );
};
