import { Box, Button, ListItem, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  useLazyGetParentChatQuery,
  useLazyGetPartnersQuery,
} from 'slices/ChatSlice';
import Chat from '../Chat';
import { PageContext } from 'store/pagecontext';
import Empty from '../Empty';

const Partners = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [partnerMessages, setPartnerMessages] = useState<any>([]);
  const [getChat, chat] = useLazyGetParentChatQuery();
  const toggle = async (id = null) => {
    setIsOpen(!isOpen);

    if (id) {
      setChatId(id);
      getChat(id);
    } else {
      setChatId(null);
    }
  };

  const {
    lastJsonMessage,
    lead,
    filter,
  }: {
    lastJsonMessage: any;
    lead: any;
    filter: any;
  } = useContext(PageContext);

  const [getPartner, data] = useLazyGetPartnersQuery();

  useEffect(() => {
    if (lead?.tender?.organizationId || lead?.advert?.organizationId) {
      getPartner({
        organizationPartnerId:
          filter === 'incoming'
            ? lead?.tender?.organizationId ?? lead?.advert?.organizationId
            : lead.organizationId,
        relationType: 'partner',
      });
    }
  }, [lead?.tender?.organizationId, lead?.advert?.organizationId]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data?.data?.filter(
    (partner: any) =>
      partner?.organization?.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );
  useMemo(() => {
    if (lastJsonMessage?.event === 'message-added') {
      const partnerSocket = chat.data?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chat.data.id
          )
        : [];

      if (partnerSocket)
        setPartnerMessages([...partnerMessages, partnerSocket]);
    } else {
      return lastJsonMessage;
    }
  }, [lastJsonMessage]);

  return (
    <>
      {isOpen ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Button
            variant="contained"
            onClick={() => toggle()}
            sx={{
              width: '100%',
              background: 'primary',
              color: 'primary',
              mr: 1,
              mt: 2,
              mb: 2,
              marginRight: 2,
            }}
          >
            Назад
          </Button>
          {chat?.data ? (
            <Box sx={{ height: '100%' }}>
              <Chat
                title={`Чат с ${filteredData.find(
                  (item: any) => item.relationId === chatId
                )?.organization.name}`}
                hide
                chat={chat?.data ?? []}
                socketMessages={partnerMessages}
              />
            </Box>
          ) : (
            <Empty />
          )}
        </Box>
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Поиск..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
          <Box
            sx={{
              position: 'relative',
              borderИottom: '#E4E4E7',
              cursor: 'pointer',
              height: 'calc(100vh - 40px)',
              width: '100%',
              overflowY: 'scroll',
              '&& .Mui-selected, && .Mui-selected:hover': {
                background: '#FAF9FE',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  borderRadius: '0px 4px 4px 0px',
                  width: 8,
                  height: '100%',
                  background: '#006FEE',
                },
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  right: 0,
                  borderRadius: '4px 0px 0px 4px',
                  width: 8,
                  height: '100%',
                  background: '#006FEE',
                },
              },
              // hover states
              '& .MuiListItemButton-root:hover': {
                bgcolor: 'orange',
                '&, & .MuiListItemIcon-root': {
                  color: 'yellow',
                },
              },
            }}
          >
            {filteredData?.length ? (
              filteredData?.map((partner: any) => (
                <React.Fragment key={partner.id}>
                  <ListItem
                    disablePadding
                    sx={{ borderBottom: '#E4E4E7' }}
                    onClick={() => toggle(partner.relationId)}
                  >
                    <Box
                      sx={{
                        marginTop: 1,
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        padding: '16px 24px',
                        borderBottom: '#E4E4E7',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Typography variant="h6">
                          {partner?.organization?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                </React.Fragment>
              ))
            ) : (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h4"
                  color="red"
                  sx={{
                    textTransform: 'uppercase',
                    marginRight: 4,
                  }}
                >
                  Партнеров нет
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Partners;
