import moment from 'moment';

// Получаем текущую дату
// Замените эту строку на вашу сохраненную дату
// Сравниваем даты
export const DateRange = (dateFromDatabase: Date) => {
  var currentDate = new Date();
  if (isSameDay(currentDate, dateFromDatabase)) {
    return `Cегодня ${moment(dateFromDatabase).format('HH:mm:ss')}`;
  } else if (isYesterday(dateFromDatabase)) {
    return `Вчера ${moment(dateFromDatabase).format('HH:mm:ss')}`;
  } else {
    // Вывод точной даты
    return `${moment(dateFromDatabase).format('YYYY-MM-DD HH:mm:ss')}`;
  }
};

// Функции для сравнения дат
function isSameDay(yesterday: Date, dateFromDatabase: Date) {
  return yesterday.toDateString() === dateFromDatabase?.toDateString();
}

function isYesterday(dateFromDatabase: Date) {
  var currentDate = new Date();
  var yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  return isSameDay(yesterday, dateFromDatabase);
}

function isWithinAWeek(dateFromDatabase: Date) {
  var currentDate = new Date();
  var oneWeekAgo = new Date(currentDate);
  oneWeekAgo.setDate(currentDate.getDate() - 7);
  return dateFromDatabase >= oneWeekAgo;
}
