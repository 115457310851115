import { BreakfastDining, Folder } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useContext } from 'react';
import theme from 'shared/theme';
import { PageContext } from 'store/pagecontext';

export const UI = () => {
  const {
    chatType,
    onHandleChangeChatType,
  }: { chatType: string; onHandleChangeChatType: Function } =
    useContext(PageContext);

  const isActive = {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    color: '#FFFFFF',
    fontSize: 9,
    background: theme.palette.primary.main,
  };
  const deActive = {
    display: 'flex',
    padding: 0,
    flexDirection: 'column',
    color: '#000',
    background: theme.palette.grey[500],
    fontSize: 9,
  };

  return (
    <Box
      sx={{
        height: '100vh',
        background: '#293A4B',
        padding: 0.5,
        display: 'flex',
        flexDirection: 'column',
        rowGap: 1,
        justifyContent: 'flex-start',
      }}
    >
      <Button
        variant="contained"
        sx={chatType === 'advert' ? isActive : deActive}
        onClick={() => onHandleChangeChatType('advert')}
      >
        <BreakfastDining />
        Объявления
      </Button>
      <Button
        variant="contained"
        sx={chatType === 'tender' ? isActive : deActive}
        onClick={() => onHandleChangeChatType('tender')}
      >
        <Folder />
        Тендеры
      </Button>
      <Button
        variant="contained"
        sx={chatType === 'company' ? isActive : deActive}
        onClick={() => onHandleChangeChatType('company')}
      >
        <Folder />
        Компания
      </Button>
      <Button
        variant="contained"
        sx={chatType === 'ankets' ? isActive : deActive}
        onClick={() => onHandleChangeChatType('ankets')}
      >
        <Folder />
        Анкеты
      </Button>
    </Box>
  );
};
