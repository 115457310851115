import * as yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const useSignInForm = (
  onOpenDrawer?: Function,
  handleClose?: () => void
) => {
  const [successSignIn, setSuccessSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPas] = useState(false);
  const router = useRouter();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: defaultValue(),
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    if (successSignIn) {
      setTimeout(() => {
        router.query.code
          ? router.push(`/go?code=${router.query.code}`)
          : router.push('/');
      }, 1300);
    }
  }, [successSignIn]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await signIn('local-login', {
        ...data,
        redirect: false,
      });
      if (response && response.ok) {
        setSuccessSignIn(true);
        toast.success('Вы успешно авторизовались');
        if (onOpenDrawer && handleClose) {
          handleClose();
          onOpenDrawer();
        }
      } else if (response && response.status === 401) {
        setLoading(false);
        toast.error('Неверный логин или пароль');
      }
    } catch (err) {
      setLoading(false);
      toast.error('Ошибка авторизации');
    }
  };

  const togglePassword = () => {
    setShowPas((prevShowPass) => !prevShowPass);
  };

  // Логика установки курсора в нужную позицию
  const inputRef = useRef<HTMLInputElement | null>(null);

  const getCursorPosition = (value: string): number => {
    // Защищаемся от неопределённого или пустого значения
    if (typeof value !== 'string' || value.trim() === '') {
      return 3; // Если строка пустая или не строка, ставим курсор в начало
    }

    const lastDigitIndex = value.search(/\d(?!.*\d)/);
    return lastDigitIndex !== -1 ? lastDigitIndex + 1 : 3;
  };

  const setCursor = (value: string): void => {
    if (inputRef.current) {
      const nextCursorPosition = Math.min(
        getCursorPosition(value),
        value.length
      );
      inputRef.current.setSelectionRange(
        nextCursorPosition,
        nextCursorPosition
      );
    }
  };

  const handleMaskedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (value: string) => void
  ) => {
    const { value } = event.target;
    if (typeof value === 'string') {
      onChange(value);
      setCursor(value);
    }
  };

  const handleFocusAndClick = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.MouseEvent<HTMLInputElement>
  ) => {
    const { value } = event.target as HTMLInputElement;
    if (typeof value === 'string') {
      setCursor(value);
    }
  };
  return {
    handleSubmit,
    onSubmit,
    control,
    handleMaskedChange,
    inputRef,
    errors,
    handleFocusAndClick,
    showPass,
    togglePassword,
    loading,
  };
};

interface FormValues {
  phone: string;
  password: string;
}
export const schema = yup.object().shape({
  phone: yup
    .string()
    .required('Телефон пользователя обязателен')
    .min(2, 'Телефон должен содержать минимум 2 символа')
    .max(16, 'Телефон не должен превышать 16 символов'),

  password: yup
    .string()
    .required('Пароль обязателен')
    .min(5, 'Пароль должен содержать минимум 5 символов')
    .max(250, 'Пароль не должен превышать 250 символов'),
});

export const defaultValue = (): FormValues => ({
  phone: '',
  password: '',
});
