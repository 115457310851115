import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { SignInForm } from 'features/SignInForm';
import { SignUpForm } from 'features/SignUpForm';
import ResetPass from 'features/ResetPass';
import { SxProps } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 15,
  outline: 'none',
};

export default function SignModal({
  title = 'Войти',
  onOpenDrawer,
  sx,
  variant,
}: {
  title: string;
  onOpenDrawer?: Function;
  sx?: SxProps;
  variant?: 'contained' | 'outlined' | 'text';
}) {
  const [step, setStep] = React.useState('1');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setStep('1');
    setOpen(false);
  };

  return (
    <div>
      <Button variant={variant} onClick={handleOpen} size="small" sx={sx}>
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {step === '1' ? (
            <SignInForm
              handleClose={handleClose}
              onOpenDrawer={onOpenDrawer}
              onChangeStep={(val: string) => setStep(val)}
            />
          ) : null}
          {step === '2' ? (
            <SignUpForm
              handleClose={handleClose}
              onChangeStep={(val: string) => setStep(val)}
            />
          ) : null}
          {step === '3' ? (
            <ResetPass
              handleClose={handleClose}
              onChangeStep={(val: string) => setStep(val)}
            />
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
