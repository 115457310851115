import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { AppBarProps } from '../type';
import theme from 'shared/theme';
import { signOut, useSession } from 'next-auth/react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { formatPhoneNumber } from 'shared/helper/phone';
import WebApi from 'services/index';
import { toast } from 'react-toastify';

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9.2)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const useHelperDashboard = () => {
  const { data: sessionData } = useSession();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const session = useSession();
  const phone = session.data?.user?.phone;
  const phoneNumber = formatPhoneNumber(phone);
  const user = session.data?.user;
  const nameAbbr = session.data?.user?.nameAbbr;
  const nameOrPhone = nameAbbr || phoneNumber;

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenElMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
    setShowMenu((prev) => !prev);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseElMenu = () => {
    setAnchorElMenu(null);
    setShowMenu((prev) => !prev);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const removeToken = async () => {
    const { err, data } = await WebApi().User.removeToken();

    if (err) {
      toast.error(err.message);
    } else {
      signOut({ callbackUrl: '/' });
    }
  };
  const isActiveTopBar = (item: any) =>
    (
      item?.href === '/'
        ? router.asPath === item.href
        : router.asPath.startsWith(`${item?.href}`)
    )
      ? theme.palette.secondary.main
      : theme.palette.secondary.light;

  const isActiveTopBarLine = (item: any) =>
    (
      item?.href === '/'
        ? router.asPath === item.href
        : router.asPath.startsWith(`${item?.href}`)
    )
      ? `2px solid ${theme.palette.secondary.main}`
      : 'none';

  const isActiveSideBar = (item: any) =>
    (
      item?.href === '/'
        ? router.asPath === item.href
        : router.asPath.startsWith(`${item?.href}`)
    )
      ? theme.palette.grey[700]
      : 'none';

  return {
    AppBar,
    Drawer,
    DrawerHeader,
    open,
    session,
    user,
    isActiveTopBar,
    isActiveTopBarLine,
    handleOpenElMenu,
    showMenu,
    anchorElMenu,
    handleCloseElMenu,
    handleOpenUserMenu,
    nameOrPhone,
    anchorElUser,
    handleCloseUserMenu,
    removeToken,
    sessionData,
    router,
    handleDrawerClose,
    handleDrawerOpen,
    isActiveSideBar,
  };
};

export default useHelperDashboard;
