import React, { useContext } from 'react';
import { Leads } from 'shared/services/interfaces/Leads';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Picture } from 'entities/Picture';
import { imageTypeAlias } from 'shared/constants';
import { PageContext } from 'store/pagecontext';

export default () => {
  const {
    tenders,
    tenderId,
    unReadMessages,
    onHandleSelectTender,
    chatType,
  }: {
    tenders: any;
    tenderId: number;
    unReadMessages: any;
    chatType: string;
    onHandleSelectTender: Function;
  } = useContext(PageContext);

  return (
    <Box
      sx={{
        position: 'relative',
        borderИottom: '#E4E4E7',
        cursor: 'pointer',
        height: '83vh',
        width: '100%',
        overflowY: 'scroll',
        '&& .Mui-selected, && .Mui-selected:hover': {
          background: '#FAF9FE',
          '&:before': {
            content: '""',
            position: 'absolute',
            borderRadius: '0px 4px 4px 0px',
            width: 8,
            height: '100%',
            background: '#006FEE',
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            borderRadius: '4px 0px 0px 4px',
            width: 8,
            height: '100%',
            background: '#006FEE',
          },
        },
        // hover states
        '& .MuiListItemButton-root:hover': {
          bgcolor: 'orange',
          '&, & .MuiListItemIcon-root': {
            color: 'yellow',
          },
        },
      }}
    >
      {tenders
        ? [...tenders]
            .sort((a, b) => {
              if (a.id == tenderId) return -1;
              if (b.id == tenderId) return 1;
              return 0;
            })
            ?.map((item: any) => {
              const unRead = unReadMessages?.chatUserMessages?.find(
                (mess: any) => mess.tenderId == item.id
              );
              return (
                <ListItem
                  disablePadding
                  key={item.id}
                  onClick={() => onHandleSelectTender(item?.id)}
                  sx={{ borderBottom: '#E4E4E7' }}
                  selected={item.id === (tenderId ?? tenders[0].id)}
                >
                  <Box
                    sx={{
                      marginTop: 1,
                      display: 'flex',
                      alignItems: 'flex-start',
                      position: 'relative',
                      flexDirection: chatType === 'advert' ? 'row' : 'column',
                      justifyContent: 'space-between',
                      padding: '16px 24px',
                      borderBottom: '#E4E4E7',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {unRead ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontSize: 12,
                          top: '50%',
                          right: 0,
                          transform: 'translate(0, -50%)',
                          background: 'red',
                        }}
                      >
                        {unRead.totalTender}
                      </Box>
                    ) : null}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography variant="body1">
                        Тендер № {item.id}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        {chatType === 'advert' ? (
                          <Picture
                            imageType={imageTypeAlias.advert}
                            advertId={item.advert?.id}
                            round={0}
                            width={50}
                            height={50}
                            borderRadius={0}
                          />
                        ) : null}
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>
                          {item.advert?.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginLeft: chatType === 'advert' ? 2 : 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography variant="h6">{item.title}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              );
            })
        : null}
    </Box>
  );
};
