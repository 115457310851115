'use client';
import React, { useState, useEffect } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocationOn } from '@mui/icons-material';

const cities = [
  'Екатеринбург',
  'Краснодар',
  'Нижний Новгород',
  'Пермь',
  'Санкт-Петербург',
  'Тюмень',
  'Чебоксары',
  'Владивосток',
  'Иркутск',
  'Красноярск',
  'Новосибирск',
  'Ростов-на-Дону',
  'Сочи',
  'Уфа',
  'Челябинск',
  'Волгоград',
  'Казань',
  'Москва',
  'Новочебоксарск',
  'Самара',
  'Томск',
  'Хабаровск',
  'Ярославль',
];
interface Coordinates {
  lat: number;
  lng: number;
}
// Координаты городов (примерные)
const cityCoordinates: { [key: string]: Coordinates } = {
  Екатеринбург: { lat: 56.8389, lng: 60.6057 },
  Краснодар: { lat: 45.0355, lng: 38.9753 },
  'Нижний Новгород': { lat: 56.3269, lng: 44.0059 },
  Пермь: { lat: 58.0105, lng: 56.2502 },
  'Санкт-Петербург': { lat: 59.9311, lng: 30.3609 },
  Тюмень: { lat: 57.153, lng: 65.5343 },
  Чебоксары: { lat: 56.1439, lng: 47.2489 },
  Владивосток: { lat: 43.1155, lng: 131.8855 },
  Иркутск: { lat: 52.2869, lng: 104.305 },
  Красноярск: { lat: 56.0153, lng: 92.8932 },
  Новосибирск: { lat: 55.0084, lng: 82.9357 },
  'Ростов-на-Дону': { lat: 47.2357, lng: 39.7015 },
  Сочи: { lat: 43.6028, lng: 39.7342 },
  Уфа: { lat: 54.7388, lng: 55.9721 },
  Челябинск: { lat: 55.1644, lng: 61.4368 },
  Волгоград: { lat: 48.7194, lng: 44.5018 },
  Казань: { lat: 55.8304, lng: 49.0661 },
  Москва: { lat: 55.7558, lng: 37.6176 },
  Новочебоксарск: { lat: 56.1105, lng: 47.4791 },
  Самара: { lat: 53.1959, lng: 50.1008 },
  Томск: { lat: 56.4846, lng: 84.9476 },
  Хабаровск: { lat: 48.4827, lng: 135.0838 },
  Ярославль: { lat: 57.6261, lng: 39.8845 },
};

export const UI = () => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedCity, setSelectedCity] = useState('Москва');

  useEffect(() => {
    const savedCity = localStorage.getItem('selectedCity');
    if (savedCity) {
      setSelectedCity(savedCity);
    }
  }, []);

  const filteredCities = cities.filter((city) =>
    city.toLowerCase().includes(search.toLowerCase())
  );

  const toggleDrawer = (state: any) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(state);
  };

  const handleCitySelect = (city: any) => {
    setSelectedCity(city);
    setOpen(false);
    const coordinates = cityCoordinates[city];
    localStorage.setItem('selectedCity', city);
    localStorage.setItem('cityCoordinates', JSON.stringify(coordinates));
  };

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)} sx={{ color: 'white' }}>
        <LocationOn />
        <Typography variant="body1" sx={{ marginLeft: 1 }}>
          {selectedCity ? selectedCity : 'Город'}
        </Typography>
      </IconButton>
      <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            padding: 2,
            backgroundColor: '#1a1a1a',
            color: 'white',
            minHeight: '400px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">
              Ваш город{' '}
              <Box
                sx={{
                  color: '#FFDA4D',
                  display: 'inline',
                  fontSize: '34px',
                  marginLeft: 1,
                }}
              >
                {selectedCity}{' '}
              </Box>
            </Typography>
            <IconButton onClick={toggleDrawer(false)} sx={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
            <Box>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Поиск города"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{
                  margin: '16px 0',
                  backgroundColor: 'white',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  color: 'white',
                }}
              >
                {filteredCities.map((city, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        p: 2,
                        color:
                          city.toLowerCase() == selectedCity.toLowerCase()
                            ? '#FFDA4D'
                            : '#fff',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleCitySelect(city)}
                    >
                      <Typography>{city}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <img src="/map-city.svg" alt="" />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};
