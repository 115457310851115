import * as yup from 'yup';

interface FormValues {
  password: string;
  phone: string;
}
export const schema = yup.object().shape({
  password: yup
    .string()
    .required('Пароль обязателен')
    .min(5, 'Пароль должен содержать минимум 5 символов')
    .max(250, 'Пароль не должен превышать 250 символов'),

  phone: yup
    .string()
    .required('Телефон пользователя обязателен')
    .min(2, 'Телефон должен содержать минимум 2 символа')
    .max(16, 'Телефон не должен превышать 16 символов'),
});

export const defaultValue = (): FormValues => ({
  password: '',
  phone: '',
});
