/* eslint-disable @next/next/no-img-element */
import { FC, useEffect, useState, ComponentProps } from 'react';
import WebApi from '../../shared/services';
import { useSession } from 'next-auth/react';
import { Avatar as MuiAvatar, SxProps } from '@mui/material';
import { ImageTypeAlias } from 'shared/constants';
import { Image } from 'shared/types/Image';

type AvatarProps = ComponentProps<typeof MuiAvatar> &
  Partial<{
    userId: number;
    advertId: number;
    companyId: number;
    imageType: ImageTypeAlias;
    labelStyle: React.CSSProperties;
    organizationId: number;
    catalogId: number;
    docId: number;
  }>;

const NEXT_PUBLIC_IMG = process.env.NEXT_PUBLIC_IMG;

const Avatar: FC<AvatarProps> = ({
  userId,
  advertId,
  imageType,
  organizationId,
  catalogId,
  docId,
  ...muiProps
}: AvatarProps) => {
  const { data: sessionData } = useSession();
  const [avatar, setAvatar] = useState<Partial<Image>>();
  useEffect(() => {
    if (!avatar) {
      const getImg = async () => {
        const args: Partial<Image> = {};
        if (advertId) {
          args.advertId = advertId;
        }
        if (userId) {
          args.userId = userId;
        }
        if (imageType) {
          args.imageType = imageType;
        }
        if (organizationId) {
          args.organizationId = organizationId;
        }
        if (catalogId) {
          args.catalogId = catalogId;
        }
        if (docId) {
          args.docId = docId;
        }
        const { data } = await WebApi(sessionData).Image.getImage(args);

        setAvatar!(data?.length ? data[data.length - 1] : null);
      };
      getImg();
    }
  }, [advertId, userId, organizationId, catalogId, docId, imageType, avatar]);
  const src = avatar?.filepath
    ? `${NEXT_PUBLIC_IMG}/${avatar?.filepath}`
    : undefined;
  return <MuiAvatar id={String(avatar?.id)} src={src} {...muiProps} />;
};

// Фото-заглушка
//'https://react.semantic-ui.com/images/wireframe/image.png'
export default Avatar;
