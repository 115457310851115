import React, { useContext } from 'react';
import { Leads } from 'shared/services/interfaces/Leads';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Picture } from 'entities/Picture';
import { imageTypeAlias } from 'shared/constants';
import { PageContext } from 'store/pagecontext';

export const UI = () => {
  const {
    leads,
    leadId,
    unReadMessages,
    onHandleSelectChat,
    chatType,
  }: {
    leads: Leads[];
    leadId: number;
    unReadMessages: any;
    chatType: string;
    onHandleSelectChat: Function;
  } = useContext(PageContext);
  return (
    <Box
      sx={{
        position: 'relative',
        borderИottom: '#E4E4E7',
        cursor: 'pointer',
        height: '73vh',
        width: '100%',
        overflowY: 'scroll',
        '&& .Mui-selected, && .Mui-selected:hover': {
          background: '#FAF9FE',
          '&:before': {
            content: '""',
            position: 'absolute',
            borderRadius: '0px 4px 4px 0px',
            width: 8,
            height: '100%',
            background: '#006FEE',
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            borderRadius: '4px 0px 0px 4px',
            width: 8,
            height: '100%',
            background: '#006FEE',
          },
        },
        // hover states
        '& .MuiListItemButton-root:hover': {
          bgcolor: 'orange',
          '&, & .MuiListItemIcon-root': {
            color: 'yellow',
          },
        },
      }}
    >
      {leads && leads.length ? (
        [...leads]
          .sort((a, b) => {
            if (a.id == leadId) return -1;
            if (b.id == leadId) return 1;
            return 0;
          })
          ?.map((item: any) => {
            const unRead = unReadMessages?.chatUserMessages?.find(
              (mess: any) => mess.leadId === item.id
            );
            return (
              <ListItem
                disablePadding
                key={item.id}
                onClick={() => onHandleSelectChat(item?.id)}
                sx={{ borderBottom: '#E4E4E7' }}
                selected={item.id === (leadId ?? leads[0].id)}
              >
                <Box
                  sx={{
                    marginTop: 1,
                    display: 'flex',
                    alignItems: 'flex-start',
                    position: 'relative',
                    flexDirection: chatType === 'advert' ? 'row' : 'column',
                    justifyContent: 'space-between',
                    padding: '16px 24px',
                    borderBottom: '#E4E4E7',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {unRead ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        width: 20,
                        height: 20,
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: 12,
                        top: '50%',
                        left: 0,
                        transform: 'translate(0, -50%)',
                        background: 'red',
                      }}
                    >
                      {unRead.totalLead}
                    </Box>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography variant="body1">Заявка № {item.id}</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 1,
                      }}
                    >
                      {chatType === 'advert' ? (
                        <Picture
                          imageType={imageTypeAlias.advert}
                          advertId={item.advert?.id}
                          round={0}
                          width={50}
                          height={50}
                          borderRadius={0}
                        />
                      ) : null}
                      <Typography variant="body2" sx={{ marginLeft: 1 }}>
                        {item.advert?.title}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      marginLeft: chatType === 'advert' ? 2 : 0,
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body2" color="gray" fontSize={10}>
                        Заказчик
                      </Typography>
                      <Typography variant="body1" fontSize={12}>
                        {item?.organization?.title}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 2,
                      }}
                    >
                      <Typography variant="body2" color="gray" fontSize={10}>
                        Поставщик
                      </Typography>
                      <Typography variant="body1" fontSize={12}>
                        {item?.advert?.organization?.title ??
                          item?.tender?.organization?.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ListItem>
            );
          })
      ) : (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h3"
            color="red"
            sx={{ textTransform: 'uppercase' }}
          >
            Заявок нет
          </Typography>
        </Box>
      )}
    </Box>
  );
};
