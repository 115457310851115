import { SvgIcon } from '@mui/material'
import React from 'react'

const Projects = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M17.5 5.83333V14.1667C17.5 16.6667 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V5.83333C2.5 3.33333 3.75 1.66666 6.66667 1.66666H13.3333C16.25 1.66666 17.5 3.33333 17.5 5.83333Z" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.9168 1.66666V8.21665C12.9168 8.58332 12.4835 8.76665 12.2168 8.52498L10.2835 6.74169C10.1252 6.59169 9.87514 6.59169 9.71681 6.74169L7.78352 8.52498C7.51685 8.76665 7.0835 8.58332 7.0835 8.21665V1.66666H12.9168Z" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.0415 11.6667H14.5832" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 15H14.5833" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  )
}

export default Projects
