import { SvgIcon } from '@mui/material'
import React from 'react'

const Company = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M1.6665 18.3333H18.3332" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 1.66667C11.3333 2.20001 12.8333 2.20001 14.1667 1.66667V4.16667C12.8333 4.70001 11.3333 4.70001 10 4.16667V1.66667Z" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 4.16667V6.66667" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.1668 6.66667H5.8335C4.16683 6.66667 3.3335 7.50001 3.3335 9.16667V18.3333H16.6668V9.16667C16.6668 7.50001 15.8335 6.66667 14.1668 6.66667Z" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.81689 10H16.1836" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.6582 10V18.3333" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M9.9917 10V18.3333" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round" />
      <path d="M13.3252 10V18.3333" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round" />
    </SvgIcon>
  )
}

export default Company
