import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { PageContext } from 'store/pagecontext';

export const UI = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const {
    chatType,
  }: {
    chatType: string;
  } = useContext(PageContext);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateRemainingHeight = () => {
      if (divRef.current) {
        const blockTop = divRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        setHeight(windowHeight - blockTop - 10);
      }
    };
    // Вызовите функцию при монтировании компонента
    updateRemainingHeight();
    window.addEventListener('resize', updateRemainingHeight);
    // Удалите обработчик, когда компонент будет размонтирован
    return () => {
      window.removeEventListener('resize', updateRemainingHeight);
    };
  }, []);

  return (
    <Box
      sx={{
        marginBottom: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box
          ref={divRef}
          sx={{
            height: `${height}px`,
            marginTop: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h3"
            color="red"
            sx={{ textTransform: 'uppercase' }}
          >
            Нет доступа
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
