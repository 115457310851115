'use client';
import { useEffect, useRef } from 'react';

const useNotificationSound = (audioFile: string | undefined) => {
  const audioRef = useRef<any>(null);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      audioRef.current = new Audio(audioFile);
    }
  }, [audioFile]);
  const playSound = () => {
    audioRef.current.play();
  };

  return playSound;
};

export default useNotificationSound;
