import { SvgIcon } from '@mui/material'
import React from 'react'

const Security = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M8.7418 1.85833L4.58346 3.425C3.62513 3.78333 2.8418 4.91666 2.8418 5.93333V12.125C2.8418 13.1083 3.4918 14.4 4.28346 14.9917L7.8668 17.6667C9.0418 18.55 10.9751 18.55 12.1501 17.6667L15.7335 14.9917C16.5251 14.4 17.1751 13.1083 17.1751 12.125V5.93333C17.1751 4.90833 16.3918 3.775 15.4335 3.41666L11.2751 1.85833C10.5668 1.6 9.43346 1.6 8.7418 1.85833Z" stroke="#F4F4F5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.0002 10.4167C10.9206 10.4167 11.6668 9.67047 11.6668 8.74999C11.6668 7.82952 10.9206 7.08333 10.0002 7.08333C9.07969 7.08333 8.3335 7.82952 8.3335 8.74999C8.3335 9.67047 9.07969 10.4167 10.0002 10.4167Z" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 10.4167V12.9167" stroke="#F4F4F5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  )
}

export default Security
