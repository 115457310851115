import { useContext, useMemo, useState } from 'react';
import Avatar from 'features/Avatar/Avatar';
import { imageTypeAlias } from 'shared/constants';
import { PageContext } from 'store/pagecontext';
import { useLazyRemoveUserFromChatQuery } from 'slices/ChatSlice';
import { CloseSharp } from '@mui/icons-material';
import { Box } from '@mui/material';

import List from './List';

export const UI = ({ hide, chatId, chatUsers }: any) => {
  const {
    lead,
    lastMessage,
    users,
  }: {
    lead: any;
    lastMessage: any;
    users: any;
  } = useContext(PageContext);

  const [userList, setUserList] = useState([{}]);

  useMemo(() => {
    if (lastMessage?.event === 'user-added') {
      if (lastMessage.data.chatUsers[0].chatUser.chatId === chatId) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setUserList([
          ...userList,
          ...lastMessage.data.chatUsers.map((item: any) => ({
            userId: item.user.id,
            id: item.chatUser.id,
          })),
        ]);
      }
    } else if (lastMessage?.event === 'user-removed') {
      if (lastMessage.data.chatUsers[0].chatUser.chatId === chatId) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setUserList([
          ...userList.filter(
            (item: any) =>
              item.userId !== lastMessage.data.chatUsers[0].chatUser.userId
          ),
        ]);
      }
    }
  }, [lastMessage]);

  useMemo(() => {
    if (chatUsers?.length) {
      setUserList(chatUsers);
    }
  }, [chatUsers]);

  const [removerUser, response] = useLazyRemoveUserFromChatQuery();
  const onHandleRemove = (id: number) => {
    removerUser(id);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        justifyContent: 'flex-end',
      }}
    >
      {userList?.map((item: any) => (
        <Box key={item.id} sx={{ position: 'relative' }}>
          {users?.data?.find((user: any) => item.userId === user.id) ? (
            <CloseSharp
              fontSize="small"
              onClick={() => onHandleRemove(item.id)}
              sx={{
                position: 'absolute',
                zIndex: 2,
                bottom: 25,
                left: 25,
                cursor: 'pointer',
              }}
            />
          ) : null}
          <Avatar
            userId={item.userId}
            title={item?.user?.name}
            imageType={imageTypeAlias.userAvatar}
          />
        </Box>
      ))}
      <List
        chatUsers={userList}
        chatId={chatId}
        organizationId={
          !hide
            ? lead?.organizationId
            : lead?.advert?.organization?.id
            ? lead?.advert.organization.id
            : lead?.tender?.organization?.id
        }
      />
    </Box>
  );
};
