/* eslint-disable @next/next/no-img-element */
import {
  ChangeEvent,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import WebApi from '../../shared/services';
import { useSession } from 'next-auth/react';
import {
  Box,
  Button,
  Avatar as MuiAvatar,
  SxProps,
  Typography,
} from '@mui/material';
import { ImageTypeAlias, imageTypeAlias } from 'shared/constants';
import { Image } from 'shared/types/Image';
import { useUserAvatar } from './useUserAvatar';
import { Session } from 'next-auth';
import { WebApiResponse } from 'shared/types/WebApiTypes';
import theme from 'shared/theme';

type UserAvatarProps = Partial<{
  sx: SxProps;
  userId: number;
  isUpload: boolean;
  imgStyle: React.CSSProperties;
  companyId: number;
  imageType: ImageTypeAlias;
  labelStyle: React.CSSProperties;
}>;

type UserAvatarArgs = {
  id?: number;
  file: Blob;
  filename: string;
  userId: number;
  sessionData: Session | null;
};

const NEXT_PUBLIC_IMG = process.env.NEXT_PUBLIC_IMG;

const sendUserAvatar = async (
  args: UserAvatarArgs
): Promise<WebApiResponse<{ data: { data: Image } }>> => {
  const { id, file, userId, filename, sessionData } = args;
  const img: Partial<Image> = {
    id,
    userId,
    filename,
    imageType: imageTypeAlias.userAvatar,
  };
  const body = new FormData();
  body.append('file', file);
  body.append('image', JSON.stringify(img));
  const resp = id
    ? await WebApi(sessionData).Image.update(body)
    : await WebApi(sessionData).Image.create(body);
  return resp;
};

const getUserAvatar = async (
  args: Pick<UserAvatarArgs, 'userId' | 'sessionData'>
) => {
  const { userId, sessionData } = args;
  const resp = await WebApi(sessionData).Image.getImage({
    userId,
    imageType: imageTypeAlias.userAvatar,
  });
  return resp;
};

const UserAvatar = forwardRef(
  ({ sx, userId, imgStyle, isUpload, labelStyle }: UserAvatarProps, ref) => {
    UserAvatar.displayName = 'UserAvatar';

    const { data: sessionData, update } = useSession();
    const { userAvatar, setUserAvatar } = useUserAvatar();
    const [file, setFile] = useState<Blob>();

    useImperativeHandle(ref, () => ({
      sendUserAvatarHandler: async (args: UserAvatarArgs) => {
        const { userId } = args;
        if (file && userId) {
          const resp = await sendUserAvatar({
            id: userAvatar?.id,
            file,
            userId,
            sessionData,
            filename: file.name,
          });
          const image = resp.data?.data?.data;
          setUserAvatar!(image);
          return resp;
        }
        return {};
      },
    }));

    useEffect(() => {
      if (!userAvatar) {
        const sessionUserAvatar = (sessionData as any)?.user?.userAvatar;
        if (sessionUserAvatar) {
          setUserAvatar!(sessionUserAvatar);
        } else if (userId) {
          getUserAvatar({ userId, sessionData }).then(async ({ data }) => {
            const userAvatar = data?.length ? data[data.length - 1] : null;
            if (userAvatar) {
              await update({ user: { userAvatar } });
              setUserAvatar!(userAvatar);
            }
            return data;
          });
        }
      }
    }, [userAvatar, userId, getUserAvatar]);
    const imgId = 'user-avatar-image-' + imageTypeAlias.userAvatar;
    const inputId = 'user-avatar-upload-' + imageTypeAlias.userAvatar;

    function showImage(event: ChangeEvent<HTMLInputElement>) {
      const imagElt = document.getElementById(imgId) as HTMLImageElement;
      if (imagElt && event.target.files?.length) {
        const reader = new FileReader();
        reader.onload = function () {
          if (reader.result) {
            imagElt.src = reader.result as string;
          }
        };
        reader.readAsDataURL(event.target.files[0]);
        setFile(event.target.files[0]);
      }
    }
    let img: React.ReactElement;
    const src = userAvatar?.filepath
      ? `${NEXT_PUBLIC_IMG}${userAvatar?.filepath}`
      : 'https://react.semantic-ui.com/images/wireframe/image.png';
    if (isUpload) {
      img = (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            mb: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              id={imgId}
              style={imgStyle}
              src={src}
              alt="аватар пользователя"
            />
            <input
              type="file"
              accept="image/*"
              id={inputId}
              style={{ display: 'none' }}
              onChange={showImage}
            />
          </Box>

          <Box
            sx={{
              // cursor: 'pointer',
              width: '100%',
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <label htmlFor={inputId} style={labelStyle}>
              <Button
                size="small"
                sx={{
                  width: '100%',
                  mt: '1rem',
                }}
                onClick={() => document.getElementById(inputId)?.click()}
              >
                Загрузить аватар
              </Button>
            </label>
            <Typography variant="body1" color={theme.palette.grey[600]}>
              jpg, jpeg, png; не более 5 Мб
            </Typography>
          </Box>
        </Box>
      );
    } else {
      img = <MuiAvatar alt="аватар пользователя" src={src} sx={sx} />;
    }
    return img;
  }
);

export default UserAvatar;
