import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import theme from 'shared/theme';
import Link from 'next/link';
import { PageContext } from 'store/pagecontext';

export const UI = () => {
  const {
    tenders,
    tenderId,
    lead,
  }: { tenderId: number; tenders: any; lead: any } = useContext(PageContext);
  const tender = tenders?.find((item: any) => item.id === tenderId);
  return (
    <Box>
      <Typography variant="h5">Данные по тендеру</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto',
          width: '100%',
          flexWrap: 'wrap',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
          >
            Наименование
          </Typography>
          <Typography variant="body2">{lead?.tender?.title}</Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
          >
            Описание
          </Typography>
          <Typography variant="body2">{lead?.tender?.description}</Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
          >
            Стоимость
          </Typography>
          <Typography variant="body2">
            {new Intl.NumberFormat('ru-RU').format(
              lead?.tender?.price ?? lead?.tender?.price
            )}{' '}
            {lead?.tender?.currency?.sym ?? lead?.tender?.data?.price}
          </Typography>
        </Box>
        {lead?.tender?.startDate ? (
          <Box>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Дата начала
            </Typography>
            <Typography variant="body2">
              {moment(lead?.tender?.startDate).format('DD.MM.YYYY')}
            </Typography>
          </Box>
        ) : null}
        {lead?.tender?.endDate ? (
          <Box>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey[600], fontSize: '11px' }}
            >
              Дата окончания
            </Typography>
            <Typography variant="body2">
              {moment(lead?.tender?.endDate).format('DD.MM.YYYY')}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
