import { SvgIcon } from '@mui/material'
import React from 'react'

const Tender = () => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M6.6665 1.66666V4.16666" stroke="#F4F4F5" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.3335 1.66666V4.16666" stroke="#F4F4F5" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.5 7.08333V14.1667C17.5 16.6667 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V7.08333C2.5 4.58333 3.75 2.91666 6.66667 2.91666H13.3333C16.25 2.91666 17.5 4.58333 17.5 7.08333Z" stroke="#F4F4F5" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.6665 9.16667H13.3332" stroke="#F4F4F5" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.6665 13.3333H9.99984" stroke="#F4F4F5" strokeWidth="1.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  )
}

export default Tender
