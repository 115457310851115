import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { Box, Divider, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import Icons from 'shared/Icons/Notifications';
import useWebSocket from 'react-use-websocket';
import { useSession } from 'next-auth/react';
import theme from 'shared/theme';
import WebApi from 'services/index';
import { DateRange } from 'shared/helper/dateRange';
import { PageContext } from 'shared/lib/toolkit/store/pagecontext';
import Chats from 'models/Chats';
import Types from './Types';

interface Props {
  id?: number;
  type?: string;
  organizationId?: string | string[] | null;
}
interface INotification {
  data: {};
  event: string;
  id: number;
  read: boolean;
  userId: number;
}

export const UI = ({}: Props) => {
  const { data: sessionData } = useSession();
  const [open, setOpen] = useState<boolean>(false);
  const [list, setList] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [read, setRead] = useState(false);

  const protocols = encodeURIComponent(
    JSON.stringify({ authorization: sessionData?.user?.accessToken })
  );
  const { lastJsonMessage, readyState }: any = useWebSocket(
    `${process.env.NEXT_PUBLIC_NOTIFICATION}`,
    { protocols }
  );
  const readNotifications = async (item: any) => {
    if (sessionData?.user && !item.read) {
      try {
        await WebApi().Notification.readNotifications({
          id: item.id,
          read: true,
        });
        getReadList();
      } catch (err) {
        console.log(err);
      }
    }
  };
  const getRead = async () => {
    try {
      const data = await WebApi().Notification.getList(
        typeof read === 'boolean' ? { read } : {}
      );
      setList(data);
    } catch (err) {
      setList([]);
    }
  };

  const getReadList = async () => {
    try {
      const data = await WebApi().Notification.getList({ read: false });
      const filteredData = data.filter(
        (item: INotification) => item.event !== 'new-employee'
      );
      setCount(filteredData.length);
    } catch (err) {
      setCount(0);
    }
  };

  useMemo(() => {
    getRead();
  }, [read]);

  useMemo(() => {
    getRead();
  }, [open]);

  useMemo(() => {
    getReadList();
  }, []);

  useEffect(() => {
    if (lastJsonMessage?.data) {
      setList([lastJsonMessage, , ...list]);
      setCount(count + 1);
    }
  }, [lastJsonMessage]);

  return (
    <Box>
      <PageContext.Provider value={{}}>
        <Modal onClose={() => setOpen(false)} open={open}>
          <Box
            sx={{
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              position: 'absolute',
              gap: 1,
              width: 500,
              top: 0,
              right: 0,
              background: '#fff',
              height: '100vh',
              '@media (max-width: 638px)': {
                left: 58,
              },
            }}
          >
            <Close
              onClick={() => setOpen(false)}
              sx={{ position: 'absolute', right: 20, cursor: 'pointer' }}
            />
            <Box
              sx={{
                height: '8vh',
              }}
            >
              <Types read={read} setRead={setRead} />
            </Box>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <Box
              sx={{
                height: '92vh',
                overflowY: 'auto',
                zIndex: 999999999,
              }}
            >
              {Array.isArray(list) && list.length ? (
                list?.map((item: any) => {
                  if (item?.event === 'lead-created') {
                    return (
                      <Box
                        key={item.id}
                        onClick={() => readNotifications(item)}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          border: `1px solid ${theme.palette.grey[400]}`,
                          background: theme.palette.grey[100],
                          p: 1.5,
                          m: 0.5,
                          borderRadius: '12px',
                          ':hover': {
                            backgroundColor: '#006FEE',
                            color: '#fff',
                          },
                        }}
                      >
                        <Typography variant="body1">
                          Вам пришла заявка №
                          {item?.data?.tenderId
                            ? item.data?.tender?.id
                            : item.data?.advert?.id}{' '}
                          по{' '}
                          {item?.data?.tenderId
                            ? item.data?.tender?.title
                            : item.data?.advert?.title}
                        </Typography>
                        <Divider
                          sx={{ height: 50, ml: 1, mr: 1 }}
                          orientation="vertical"
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 150,
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ width: '100%' }}>
                            <Chats
                              id={item.data?.id}
                              type="incoming"
                              chat={item?.data?.advert ? 'advert' : 'tender'}
                            />
                          </Box>
                          <Typography variant="body1">
                            {DateRange(new Date(item?.ins) as Date) as string}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                  if (item?.event === 'mini-tender-created') {
                    return (
                      <Link
                        href={`/tenders/public/${item.data?.category?.alias}/${item.data?.id}`}
                        key={item.id}
                      >
                        <Box
                          onClick={() => readNotifications(item)}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: `1px solid ${theme.palette.grey[400]}`,
                            background: theme.palette.grey[100],
                            p: 1.5,
                            m: 0.5,
                            borderRadius: '12px',
                            ':hover': {
                              backgroundColor: '#006FEE',
                              color: '#fff',
                            },
                          }}
                        >
                          <Typography variant="body1">
                            Был объявлен тендер №{item.data?.id} по категории{' '}
                            {item.data?.category?.name}
                          </Typography>
                          <Divider
                            sx={{ height: 50, ml: 1, mr: 1 }}
                            orientation="vertical"
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: 150,
                              alignItems: 'center',
                            }}
                          >
                            <Box sx={{ width: '100%' }}></Box>
                            <Typography variant="body1">
                              {DateRange(new Date(item?.ins) as Date) as string}
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    );
                  }
                  // if (item?.event === 'new-employee') {
                  //   return (
                  //     <Link
                  //       href={`/company/${item.data?.organizationId}/structure`}
                  //       key={item.id}
                  //     >
                  //       <Box
                  //         onClick={() => readNotifications(item)}
                  //         sx={{
                  //           display: 'flex',
                  //           justifyContent: 'space-between',
                  //           alignItems: 'center',
                  //           border: `1px solid ${theme.palette.grey[400]}`,
                  //           background: theme.palette.grey[100],
                  //           p: 1.5,
                  //           m: 0.5,
                  //           borderRadius: '12px',
                  //           ':hover': {
                  //             backgroundColor: '#006FEE',
                  //             color: '#fff',
                  //           },
                  //         }}
                  //       >
                  //         <Typography variant="body1">
                  //           В структуру компании был добавлен новый сотрудник
                  //         </Typography>
                  //         <Box
                  //           sx={{
                  //             display: 'flex',
                  //             flexDirection: 'column',
                  //             width: 150,
                  //             alignItems: 'center',
                  //           }}
                  //         >
                  //           <Box sx={{ width: '100%' }}></Box>
                  //           <Typography variant="body1">
                  //             {DateRange(new Date(item?.ins) as Date) as string}
                  //           </Typography>
                  //         </Box>
                  //       </Box>
                  //     </Link>
                  //   );
                  // }
                  if (item?.event === 'organization-join') {
                    return (
                      <Box
                        key={item.data.id}
                        onClick={() => readNotifications(item)}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          border: `1px solid ${theme.palette.grey[400]}`,
                          background: theme.palette.grey[100],
                          p: 1.5,
                          m: 0.5,
                          borderRadius: '12px',
                          ':hover': {
                            backgroundColor: '#006FEE',
                            color: '#fff',
                          },
                        }}
                      >
                        <Typography variant="body1">
                          В компанию {item.data?.organization?.name} был
                          добавлен новый сотрудник{' '}
                          <Link
                            href={`/user/${item.data?.organizationUserRole?.user?.id}`}
                          >
                            {item.data?.organizationUserRole?.user?.nameAbbr}
                          </Link>
                        </Typography>
                        <Divider
                          sx={{ height: 50, ml: 1, mr: 1 }}
                          orientation="vertical"
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: 150,
                            alignItems: 'center',
                          }}
                        >
                          <Box sx={{ width: '100%' }}></Box>
                          <Typography variant="body1">
                            {DateRange(new Date(item?.ins) as Date) as string}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                })
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h5">Новых уведомлений нет</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
        <Box
          sx={{
            color: theme.palette.grey[900],
            cursor: 'pointer',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            gap: 1,
            // pt: 0.5,
            pr: 2,
            ':hover': { opacity: 0.7 },
            [theme.breakpoints.down('lg')]: {
              pr: 0,
            },
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          {count ? (
            <Box
              sx={{
                position: 'absolute',
                right: 15,
                borderRadius: '50%',
                p: 1,
                background: 'red',
                width: 3,
                height: 3,
                top: -6,
                color: '#fff',
                fontSize: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {count}
            </Box>
          ) : null}
          <Box>
            <Icons />
          </Box>
          <Box
            sx={{
              pr: 3,
              display: 'none',
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontFamily: 'Rubik',
                fontWeight: 500,
                color: theme.palette.secondary.light,
              }}
            >
              Уведомления
            </Typography>
          </Box>
        </Box>
      </PageContext.Provider>
    </Box>
  );
};
