import React, { useContext, useEffect, useMemo } from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { Picture } from 'entities/Picture';
import { imageTypeAlias } from 'shared/constants';
import { PageContext } from 'store/pagecontext';

export const UI = () => {
  const {
    company,
    leadId,
    onHandleSelectChat,
  }: {
    company: any[];
    leadId: number;
    onHandleSelectChat: Function;
  } = useContext(PageContext);

  return (
    <List
      disablePadding
      sx={{
        position: 'relative',
        borderИottom: '#E4E4E7',
        cursor: 'pointer',
        height: '100vh',
        width: '100%',
        overflowY: 'scroll',
        '&& .Mui-selected, && .Mui-selected:hover': {
          background: '#FAF9FE',
          '&:before': {
            content: '""',
            position: 'absolute',
            borderRadius: '0px 4px 4px 0px',
            width: 8,
            height: '100%',
            background: '#006FEE',
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            borderRadius: '4px 0px 0px 4px',
            width: 8,
            height: '100%',
            background: '#006FEE',
          },
        },
        // hover states
        '& .MuiListItemButton-root:hover': {
          bgcolor: 'orange',
          '&, & .MuiListItemIcon-root': {
            color: 'yellow',
          },
        },
      }}
    >
      {company && company.length ? (
        [...company]
          .sort((a, b) => {
            if (a.id == leadId) return -1;
            if (b.id == leadId) return 1;
            return 0;
          })
          ?.map((item: any) => (
            <ListItem
              disablePadding
              key={item.id}
              onClick={() => onHandleSelectChat(item?.id)}
              sx={{ borderBottom: '#E4E4E7' }}
              selected={item.id === (leadId ?? company[0].id)}
            >
              <Box
                sx={{
                  marginTop: 1,
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  padding: '16px 24px',
                  borderBottom: '#E4E4E7',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 2,
                    justifyContent: 'flex-start',
                  }}
                >
                  <Picture
                    imageType={imageTypeAlias.organizationLogo}
                    companyId={item?.id}
                    round={0}
                    width={50}
                    height={50}
                    borderRadius={0}
                  />
                  <Typography variant="h5">{item.title}</Typography>
                </Box>
              </Box>
            </ListItem>
          ))
      ) : (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="h4"
            color="red"
            sx={{ textTransform: 'uppercase' }}
          >
            Компаний нет
          </Typography>
        </Box>
      )}
    </List>
  );
};
