import { UserType } from "types/User";

export const adminRole = ({ user, children, notShow }: { user: UserType, children: any, notShow: boolean }) => {
  const rolesArray = (user && user.roles) || [];
  const isAdmin = rolesArray.find((role) => {
    return role.alias === 'admin';
  });

  return notShow ? (isAdmin ? null : children) : (isAdmin ? children : null);
}

