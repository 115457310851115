import { Box, Button } from '@mui/material';
import { useContext } from 'react';
import theme from 'shared/theme';
import { PageContext } from 'store/pagecontext';

export const UI = ({
  read,
  setRead,
}: {
  read: boolean | undefined;
  setRead: Function;
}) => {
  const isActive = {
    color: theme.palette.grey[900],
    background: theme.palette.secondary.main,
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: 1,
        display: 'flex',
        columnGap: 1,
        background: '#fff',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="contained"
        sx={
          typeof read === 'boolean' && !read
            ? isActive
            : {
                color: '#000',
                background: theme.palette.grey[300],
              }
        }
        onClick={() => setRead(false)}
      >
        Непрочитанные
      </Button>
      <Button
        variant="contained"
        sx={
          read === undefined
            ? isActive
            : {
                color: '#000',
                background: theme.palette.grey[300],
              }
        }
        onClick={() => setRead(undefined)}
      >
        Все
      </Button>
      <Button
        variant="contained"
        sx={
          typeof read === 'boolean' && read
            ? isActive
            : {
                color: '#000',
                background: theme.palette.grey[300],
              }
        }
        onClick={() => setRead(true)}
      >
        Прочитанные
      </Button>
    </Box>
  );
};
