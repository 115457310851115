import React, { createContext, useContext, useState } from 'react'
import { Image } from 'shared/types/Image';

export const UserAvatarContext = createContext<{
  userAvatar: Image|undefined,
  setUserAvatar: React.Dispatch<React.SetStateAction<Image|undefined>>|undefined,
}>({
  userAvatar:    undefined,
  setUserAvatar: undefined,
})

type UserAvatarProvider = {
  children: React.ReactNode;
}

export const UserAvatarProvider = ({ children }: UserAvatarProvider) => {
  const [userAvatar, setUserAvatar] = useState<Image>();
  return (
    <UserAvatarContext.Provider value={{ userAvatar, setUserAvatar }}>
      {children}
    </UserAvatarContext.Provider>
  )
}

export const useUserAvatar = () => {
  const { userAvatar, setUserAvatar } = useContext(UserAvatarContext)
  return { userAvatar, setUserAvatar }
}
