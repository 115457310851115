import { useContext, useState } from 'react';
import { PageContext } from 'store/pagecontext';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Modal,
} from '@mui/material';
import theme from 'shared/theme';
import { FilterAlt } from '@mui/icons-material';

export const UI = () => {
  const {
    filter,
    onHandleChangeFilter,
    statuses,
    status,
    setStatus,
  }: {
    filter: string;
    onHandleChangeFilter: Function;
    statuses: any;
    status: string;
    setStatus: Function;
  } = useContext(PageContext);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState<number | string>(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setStatus(type);
    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Button variant="contained" onClick={handleOpen}>
          <FilterAlt />
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="statusess">Статусы</InputLabel>
                <Select
                  id="statusess_"
                  value={type as string}
                  labelId="statusess"
                  label="Статусы"
                  onChange={(e) => setType(e.target.value)}
                  sx={{
                    minWidth: '120px',
                    height: '36px',
                    backgroundColor: theme.palette.background.paper,
                  }}
                >
                  <MenuItem value={0}>Все</MenuItem>
                  {statuses?.map((status: any) => (
                    <MenuItem key={status.id} value={status.alias}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleClose}>Применить</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: 2,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            onHandleChangeFilter('incoming');
          }}
          sx={{
            color: filter === 'incoming' ? '#FFFFFF' : theme.palette.grey[600],
            marginRight: '8px',
            background:
              filter === 'incoming'
                ? theme.palette.primary.main
                : theme.palette.grey[500],
          }}
        >
          Продажи
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onHandleChangeFilter('outgoing');
          }}
          sx={{
            color: filter === 'outgoing' ? '#FFFFFF' : theme.palette.grey[600],
            backgroundColor:
              filter === 'outgoing'
                ? theme.palette.primary.main
                : theme.palette.grey[500],
          }}
        >
          Закупки
        </Button>
      </Box>
    </Box>
  );
};
